import React, { Component } from 'react';
import Accordion from "../../Accordion";
import BNILogo from "../../image/BNILogo.png";

class BNI extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
              <img src={BNILogo} alt='Mandiri logo' className="logoBank"/>        
              <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
                onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
              >
                <div
                  data-header="Melalui Transfer ATM"
                  className="accordion-item"
                >
                  <ol>
                    <li>Masukkan Kartu Anda</li>
                    <li>Pilih Bahasa</li>
                    <li>Masukkan PIN ATM Anda</li>
                    <li>Pilih <b>"Menu Lainnya"</b></li>
                    <li>Pilih <b>"Transfer"</b></li>
                    <li>Pilih Jenis rekening yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan")</li>
                    <li>Pilih <b>“Virtual Account Billing”</b></li>
                    <li>Masukkan nomor Virtual Account Anda <b>{vacct}</b></li>
                    <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
                    <li>Konfirmasi, apabila telah sesuai, lanjutkan transaksi</li>
                    <li>Pembayaran telah selesai dilakukan</li>
                  </ol>
                </div>
                <div data-header="Melalui Internet Banking" className="accordion-item">
                  <ol>
                    <li>Akses ibank.bni.co.id</li>
                    <li>Masukkan User ID dan password</li>
                    <li>Klik menu Transfer, lalu pilih <b>“Virtual Account Billing”</b>.</li>
                    <li>Kemudian masukan nomor Virtual Account Anda <b>NO VIRTUAL ACCOUNT BNI</b></li>
                    <li>Lalu pilih rekening debet yang akan digunakan. Kemudian tekan <b>‘’Lanjut’’</b></li>
                    <li>Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
                    <li>Masukkan Kode Otentikasi Token</li>
                    <li>Pembayaran Anda telah berhasil</li>
                  </ol>
                </div>
                <div
                  data-header="Melalui Mobile Banking"
                  className="accordion-item"
                > <ol>
                    <li>Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password</li>
                    <li>Pilih menu <b>“Transfer”</b></li>
                    <li>Pilih menu <b>“Virtual Account Billing”</b> kemudian pilih rekening debet</li>
                    <li>Masukkan nomor Virtual Account Anda <b>NO VIRTUAL ACCOUNT BNI</b> pada menu <b>“input baru”</b></li>
                    <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi</li>
                    <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
                    <li>Pembayaran Anda Telah Berhasil</li>
                  </ol>
                </div>
              </Accordion>
            </div>
          );
       }
}

export default BNI;