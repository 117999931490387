import React, { Component } from 'react';
import Accordion from "../../Accordion";
import CimbNiagaLogo from "../../image/CimbNiagaLogo.png";

class CIMB extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
            <img src={CimbNiagaLogo} alt='Bank CIMB NIAGA logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}>
              <div data-header="Melalui Transfer ATM" className="accordion-item">
                <ol>
                  <li>Masukkan kartu debit ke mesin ATM</li>
                  <li>Masukkan 6 digit PIN Anda</li>
                  <li>Pilih menu <b>Pembayaran</b> > <b>Lanjut</b> > <b> Virtual Account</b></li>
                  <li>Masukkan nomor virtual account dari tagihan servis Anda <b>{vacct}</b></li>
                  <li>Pilih rekening debit</li>
                  <li>Nomor, nama virtual account dan jumlah billing ditampilkan pada layar</li>
                  <li>Pilih <b>OK</b> untuk melakukan pembayaran</li>
                  <li>Konfirmasi pembayaran ditampilkan pada layar</li>
                </ol>
              </div>
              <div data-header="Melalui Internet Banking/CIMB Clicks" className="accordion-item">
                <ol>
                  <li>Login ke CIMB clicks</li> 
                  <li>Pilih menu Bayar Tagihan / Pay Bills</li>
                  <li>Pilih Rekening Sumber / Source Account dan Jenis Pembayaran / Payment Type > Virtual Account</li>
                  <li>Masukkan nomor virtual account dari tagihan servis Anda <b>{vacct}</b></li>
                  <li>Nomor, nama virtual account dan jumlah billing ditampilkan pada layar</li>
                  <li>Masukkan 6 digit mPIN dan tekan tombol Submit</li>
                  <li>Konfirmasi pembayaran ditampilkan pada layar</li>
                </ol>
              </div>
              <div data-header="Melalui Mobile Banking/Go Mobile" className="accordion-item"> 
                <ol>
                  <li>Login ke Go Mobile</li>
                  <li>Pilih menu TRANSFER</li>
                  <li>Pilih “Transfer to Other CIMB Niaga Account”</li>
                  <li>Pilih rekening sumber Anda: CASA atau Rekening Ponsel</li>
                  <li>Pilih CASA dan masukan virtual account dari tagihan servis Anda <b>{vacct}</b></li>
                  <li>Masukan jumlah pembayaran sesuai tagihan</li>
                  <li>Masukan mobile banking PIN</li>
                  <li>Konfirmasi pembayaran akan ditampilkan pada layar</li>

                </ol>
              </div>
            </Accordion>
          </div>
          );
       }
}

export default CIMB