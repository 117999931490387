import React, { Component } from 'react';
import Accordion from "../../Accordion";
import atmbersama from "../../image/atmbersama.jpg";

class ATMBERSAMA extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
              <img src={atmbersama} alt='Mandiri logo' className="logoBank"/>        
              <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
                onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
              >
                <div
                  data-header="Melalui Transfer ATM"
                  className="accordion-item"
                >
                  <ol>
                    <li>Input kartu ATM dan PIN Anda</li>
                    <li>Pilih menu Transfer kemudian pilih Bank Lainnya</li>
                    <li>Input Kode Keb Hana Bank: 484</li>
                    <li>Input Virtual Account Number, misal <b>{vacct}</b></li>
                    <li>Input Nominal, misal. 10000</li>
                    <li>Ikuti instruksi untuk menyelesaikan transaksi Anda</li>
                  </ol>
                </div>
                <div data-header="Melalui Mobile Internet Banking" className="accordion-item">
                  <ol>
                    <li>Login mobile / internet banking</li>
                    <li>Pilih Transfer kemudian pilih Keb Hana Bank atau input kode Keb Hana Bank: 484</li>
                    <li>Input Virtual Account Number, misal. <b>{vacct}</b></li>
                    <li>Input Nominal, misal. 10000</li>
                    <li>Ikuti instruksi untuk menyelesaikan transaksi Anda</li>
                  </ol>
                </div>
              </Accordion>
            </div>
          );
       }
}

export default ATMBERSAMA;