 import React, { Component } from 'react';
import Accordion from "../../Accordion";
import mandiriLogo from "../../image/mandiriLogo.png";

class Mandiri extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct
    return (
          <div>
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
            <img src={mandiriLogo} alt='Mandiri logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}>
              <div
                data-header="Melalui ATM"
                className="accordion-item">
                <ol>
                  <li>Input kartu ATM dan PIN Anda</li>
                  <li>Pilih Menu <b>“Bayar/Beli”</b></li>
                  <li>Pilih <b>“Lainnya”</b></li>
                  <li>Pilih <b>“Multi Payment”</b></li>
                  <li>Input <b>“70014”</b> sebagai Kode Institusi</li>
                  <li>Input Nomor Virtual Account Anda, misal. <b>{vacct}</b></li>
                  <li>Pilih <b>“Benar”</b></li>
                  <li>Pilih <b>“Ya”</b></li>
                  <li>Ambil bukti bayar Anda</li>
                  <li>Selesai</li>
                </ol>
              </div>
              <div
                data-header="Melalui Mobile Banking"
                className="accordion-item">
                <ol>
                  <li>Login Mobile Banking</li>
                  <li>Pilih <b>“Bayar”</b></li>
                  <li>Pilih <b>“Multi Payment”</b></li>
                  <li>Input <b>“Transferpay”</b> sebagai Penyedia Jasa</li>
                  <li>Input Nomor Virtual Account, misal. <b>{vacct}</b></li>
                  <li>Pilih <b>"Lanjut"</b></li>
                  <li>Input <b>"OTP dan PIN"</b></li>
                  <li>Pilih <b>"OK"</b></li>
                  <li>Bukti bayar akan ditampilkan</li>
                  <li>Selesai</li>
                </ol>
              </div>
              <div
                data-header="Melalui Internet Banking"
                className="accordion-item">
                <ol>
                  <li>Login <b>“Internet Banking”</b></li>
                  <li>Pilih <b>“Bayar”</b></li>
                  <li>Pilih <b>“Multi Payment”</b></li>
                  <li>Input <b>“Transferpay”</b> sebagai Penyedia Jasa</li>
                  <li>Input Nomor Virtual Account, misal. <b>{vacct}</b></li>
                  <li>Ceklis <b>“IDR”</b></li>
                  <li>Klik <b>“Lanjutkan”</b></li>
                  <li>Bukti bayar akan ditampilkan</li>
                  <li>Selesai</li>
                </ol>
              </div>
            </Accordion>
          </div>
          );
       }
}

export default Mandiri