import React, { Component } from 'react';
import logoAlqolam from "./image/alqolamlogo.png";

class Header extends Component{
  
  render() {
    return (
      <div className="header">
        <img src={logoAlqolam} alt='logo alqolam' className="logoAlqolam"/>        
      </div>
    );
  }
}

export default Header;
