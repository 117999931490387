import React, { Component } from 'react';
import Accordion from "../../Accordion";
import BCALogo from "../../image/BCALogo.png";

class BCA extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
          <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
            <img src={BCALogo} alt='BCA logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
            >
              <div
                data-header="Melalui Transfer ATM"
                className="accordion-item"
              >
                <ol>
                  <li>Masukkan kartu debit ke mesin ATM</li>
                  <li>Masukkan 6 digit PIN Anda</li>
                  <li>Pilih <b>“Transaksi Lainnya”</b></li>
                  <li>Pilih <b>“Transfer”</b></li>
                  <li>Pilih ke <b>“ke Rekening BCA Virtual Account”</b></li>
                  <li>Masukkan nomor BCA Virtual Account Anda <b>{vacct}</b>,  kemudian tekan <b>“Benar”</b></li>
                  <li>Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”</li>
                  <li>Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih <b>“Ya”</b></li>
                  <li>Pembayaran Anda telah selesai. Tekan <b>“Tidak”</b> untuk menyelesaikan transaksi, atau tekan <b>“Ya”</b> untuk melakukan transaksi lainnya</li>
                </ol>
              </div>
              <div data-header="Melalui Internet Banking/Klik BCA" className="accordion-item">
                <ol>
                  <li>Silahkan login pada aplikasi KlikBCA Individual</li>
                  <li>Masukkan User ID dan PIN Anda</li>
                  <li>Pilih <b>“Transfer Dana”</b></li>
                  <li>Pilih <b>“Transfer ke BCA Virtual Account”</b></li>
                  <li>Masukkan nomor BCA Virtual Account <b>{vacct}</b> atau pilih dari Daftar Transfer</li>
                  <li>Masukkan jumlah yang akan dibayarkan</li>
                  <li>Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik <b>“Kirim”</b></li>
                  <li>Pembayaran telah selesai dilakukan</li>
                </ol>
              </div>
              <div
                data-header="Melalui Mobile Banking"
                className="accordion-item"
              >
                <ol>
                  <li>Silahkan login pada aplikasi BCA Mobile</li>
                  <li>Pilih <b>“m-BCA”</b>, lalu masukkan kode akses m-BCA</li>
                  <li>Pilih <b>“m-Transfer”</b></li>
                  <li>Pilih <b>“BCA Virtual Account”</b></li>
                  <li>Masukkan nomor BCA Virtual Account Anda <b>{vacct}</b>, atau pilih dari Daftar Transfer</li>
                  <li>Masukkan jumlah yang akan dibayarkan</li>
                  <li>Masukkan PIN m-BCA Anda</li>
                  <li>Transaksi telah berhasil</li>
                </ol>
              </div>
            </Accordion>
            </div>
          );
       }
}

export default BCA;