import React, { Component } from 'react';
import imagePaid from "./image/paid.png";

class Paid extends Component{
  
  render() {
    return (
      	<div>
      		<img src={imagePaid} className="Paid" alt="Paid"/>
		    <p className="textPaymentTop">Pembayaran Anda Telah Berhasil</p>
		    <p className="textPaymentUnder">Terimakasih Anda Sudah Melakukan Pembayaran</p>
	    </div>
    );
  }
}

export default Paid;
