import React, { Component } from 'react';
import Accordion from "../../Accordion";
import kebHanaBankLogo from "../../image/kebHanaBankLogo.jpg";

class HanaBank extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
            <img src={kebHanaBankLogo} alt='Hana Bank logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}>
                <div
                  data-header="Melalui Transfer ATM"
                  className="accordion-item">
                  <ol>
                    <li>Input kartu ATM dan PIN Anda</li>
                    <li>Pilih Menu <b>“Pembayaran”</b></li>
                    <li>Pilih <b>“Lainnya”</b></li>
                    <li>Input Nomor Virtual Account <b>{vacct}</b></li>
                    <li>Pilih <b>“Benar”</b></li>
                    <li>Pilih <b>“Ya”</b></li>
                    <li>Ambil bukti bayar Anda</li>
                    <li>Selesai</li>
                  </ol>
                </div>
                <div data-header="Melalui Internet Banking" className="accordion-item">
                  <ol>
                    <li>Login Internet Banking</li>
                    <li>Pilih menu <b>“Transfer”</b> kemudian Pilih Withdrawal <b>“Account Information”</b></li>
                    <li>Pilih Account Number anda</li>
                    <li>Input Nomor Virtual Account, misal. <b>{vacct}</b></li>
                    <li>Input Biaya Service, misal. 10000</li>
                    <li>Click <b>Submit</b></li>
                    <li>Input PIN SMS </li>
                    <li>Bukti bayar akan ditampilkan</li>
                    <li>Selesai</li>
                  </ol>
                </div>
              </Accordion>
            </div>
          );
       }
}

export default HanaBank;