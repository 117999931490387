import React from "react";
import "./App.css";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Header from "./Header";
import Paid from "./Paid";
import Maybank  from "./Layout/Bank/Maybank";
import BCA from "./Layout/Bank/BCA";
import Permata from "./Layout/Bank/Permata";
import BNI from "./Layout/Bank/BNI";
import HanaBank from "./Layout/Bank/HanaBank";
import BRI from "./Layout/Bank/BRI";
import CIMB from "./Layout/Bank/CIMB";
import Danamon from "./Layout/Bank/Danamon";
import Mandiri from "./Layout/Bank/Mandiri";
// import ALTO from "./Layout/Bank/ALTO";
import ATMBERSAMA from "./Layout/Bank/ATMBERSAMA";
import axios from 'axios';
// import accordionValue from './accordionValue.js';
class Landingpage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0,
      id: null,
      dt : null,
      data : [],
      value:'',
      copied: false,
    };
 }
  componentDidMount () {
    const { payment, id , dt } = this.props.match.params

    const token = process.env.REACT_APP_SECRET_TOKEN
    let url = ''
    if(process.env.REACT_APP_SECRET_NODE === 'production'){
      if(payment === 'care'){
        url = process.env.REACT_APP_SECRET_URL + `${dt}/${id}/`
      }else if(payment === 'sufiz'){
        url = process.env.REACT_APP_SECRET_URL_SUFIZ + `${dt}/${id}/`
      }
    }else{
      if(payment === 'care'){
        url = process.env.REACT_APP_SECRET_URL_CARE_Local + `${dt}/${id}/`
      }else if(payment === 'sufiz'){
        url = process.env.REACT_APP_SECRET_URL_SUFIZ_Local + `${dt}/${id}/`
      }
    }
      this.getDjagoCheckStatusPayment(url, token, id, dt)
  }

  getDjagoCheckStatusPayment(url, token, id, dt){
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    }).then(result => {
      if(result.status === 200)
        return result.data
    }).then(resJson => {
      let data = resJson.results
      if(resJson.results.length >= 1){
        data = resJson.results[0]
      }
        return data
    }).then(data => {
      this.setState({
        id , dt, data
      })
    }).catch(error => console.log(error))
  }

  render() {
    const { data } = this.state
    const bankcd = data.bankcd
    const vacct = data.vacct
    // const vacct = "14393414313"
    // const bankcd = "OTHR"
    const status = data.status
    // const status = "new"
    return (
      <div>
          <Header/>
          {/*{ status === 'unpaid' ? <Paid/> :'' }*/}
          { status === 'paid' ? <Paid/> :'' }
          { (status === 'new' || status === 'unpaid') & bankcd === 'IBBK' ?
            <div>
              <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
              <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span >
                  <h3 className="noVA">{vacct}</h3>
                </span>
              </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
              <Maybank vacct={vacct}/>
            </div>
            : '' }
          { (status === 'new' || status === 'unpaid') & bankcd === 'CENA' ?
            <div>
              <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
              <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span ><h3 className="noVA">{vacct}</h3></span>
              </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
              <BCA vacct={vacct}/>
          </div>
            : ''}
          { (status === 'new' || status === 'unpaid') & bankcd === 'BBBA' ?
            <div>
              <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
              <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span ><h3 className="noVA">{vacct}</h3></span>
              </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
              <Permata vacct={vacct}/>
          </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'BNIN' ?
            <div>
            <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
            <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span ><h3 className="noVA">{vacct}</h3></span>
              </CopyToClipboard>
             {this.state.copied ?
            <h3 className="copied">Copy To Clipboard</h3> : null}
            <BNI vacct={vacct}/>
          </div>
            : ''
          }
           {
            (status === 'new' || status === 'unpaid') & bankcd === 'HNBN' ?
            <div>
            <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
            <CopyToClipboard text={vacct}
              onCopy={() => this.setState({copied: true})}>
              <span ><h3 className="noVA">{vacct}</h3></span>
            </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
            <HanaBank vacct={vacct}/>
          </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'BRIN' ?
            <div>
            <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
            <CopyToClipboard text={vacct}
              onCopy={() => this.setState({copied: true})}>
              <span ><h3 className="noVA">{vacct}</h3></span>
            </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
            <BRI vacct={vacct}/>
          </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'BNIA' ?
            <div>
            <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
            <CopyToClipboard text={vacct}
              onCopy={() => this.setState({copied: true})}>
              <span ><h3 className="noVA">{vacct}</h3></span>
            </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
            <CIMB vacct={vacct}/>
          </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'BDIN' ?
            <div>
            <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
            <CopyToClipboard text={vacct}
              onCopy={() => this.setState({copied: true})}>
              <span ><h3 className="noVA">{vacct}</h3></span>
            </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
            <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
           <Danamon vacct={vacct}/>
          </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'BMRI' ?
            <div>
              <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
              <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span ><h3 className="noVA">{vacct}</h3></span>
              </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
              <Mandiri vacct={vacct}/>
            </div>
            : ''
          }
          {
            (status === 'new' || status === 'unpaid') & bankcd === 'OTHR' ?
            <div>
              <h5 className="pembayaran">Silahkan lakukan pembayaran ke rekening <b>VIRTUAL ACCOUNT</b> di bawah ini:</h5>
              <CopyToClipboard text={vacct}
                onCopy={() => this.setState({copied: true})}>
                <span ><h3 className="noVA">{vacct}</h3></span>
              </CopyToClipboard>{this.state.copied ? <h3 className="copied">Copy To Clipboard</h3> : null}
              <ATMBERSAMA vacct={vacct}/>
            </div>
              : ''
            }

      </div>
    );
  }
}

export default Landingpage
