import React, { Component } from 'react';
import Accordion from "../../Accordion";
import BRILogo from "../../image/BRILogo.jpg";

class BRI extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
             <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
              <img src={BRILogo} alt='Bank Rakyat Indonesia logo' className="logoBank"/>        
              <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
                onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}>
                <div data-header="Melalui Transfer ATM" className="accordion-item">
                  <ol>
                    <li>Input kartu ATM dan PIN Anda</li>
                    <li>Pilih Menu <b>“Transaksi Lain”</b></li>
                    <li>Pilih Menu <b>“Pembayaran”</b></li>
                    <li>Pilih Menu <b>“Lain-lain”</b></li>
                    <li>Pilih Menu <b>“BRIVA”</b></li>
                    <li>Masukkan Nomor Virtual Account {vacct}</li>
                    <li>Pilih <b>“Ya”</b></li>
                    <li>Ambil bukti bayar anda</li>
                    <li>Selesai</li>
                  </ol>
                </div>
                <div data-header="Melalui Internet Banking" className="accordion-item">
                  <ol>
                    <li>Login Internet Banking</li>
                    <li>Pilih <b>“Pembayaran”</b></li>
                    <li>Pilih <b>“BRIVA”</b></li>
                    <li>Masukkan Nomor Virtual Account <b>{vacct}</b></li>
                    <li>Klik <b>“Kirim”</b></li>
                    <li>Masukkan Password</li>
                    <li>Masukkan mToken</li>
                    <li>Klik <b>"Kirim”</b></li>
                    <li>Bukti bayar akan ditampilkan</li>
                    <li>Selesai</li>

                  </ol>
                </div>
                <div
                  data-header="Melalui Mobile Banking"
                  className="accordion-item"> 
                  <ol>
                    <li>Login BRI Mobile</li>
                    <li>Pilih <b>Mobile Banking BRI</b></li>
                    <li>Pilih <b>Menu Pembayaran</b></li>
                    <li>Pilih <b>Menu BRIVA</b></li>
                    <li>Masukkan Nomor Virtual Account <b>{vacct}</b></li>
                    <li>Masukkan nominal total pembayaran servis</li>
                    <li>Klik <b>Kirim</b></li>
                    <li>Masukkan PIN Mobile</li>
                    <li>Klik <b>Kirim</b></li>
                    <li>Bukti bayar akan dikirim melalui sms</li>
                    <li>Selesai</li>

                  </ol>
                </div>
              </Accordion>
            </div>
          );
       }
}

export default BRI;