import React, { Component } from 'react';
import Accordion from "../../Accordion";
import PermataBankLogo from "../../image/PermataBankLogo.png";
class Permata extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
<div>
<h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
            <img src={PermataBankLogo} alt='Permata Bank logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
            >
              <div
                data-header="Melalui Transfer ATM"
                className="accordion-item"
              >
                <ol>
                  <li>Input kartu ATM dan PIN Anda</li>
                  <li>Pilih Menu Transaksi Lainnya</li>
                  <li>Pilih <b>“Pembayaran”</b></li>
                  <li>Pilih <b>“Pembayaran Lain-lain”</b></li>
                  <li>Pilih <b>“Virtual Account”</b></li>
                  <li>Input Nomor Virtual Account <b>{vacct}</b></li>
                  <li>Pilih <b>“Benar”</b></li>
                  <li>Pilih <b>“Ya”</b></li>
                  <li>Ambil bukti bayar anda</li>
                  <li>Selesai</li>
                </ol>
              </div>
              <div data-header="Melalui Internet Banking" className="accordion-item">
                <ol>
                  <li>Login Internet Banking</li>
                  <li>Pilih <b>“Pembayaran Tagihan”</b></li>
                  <li>Pilih <b>“Virtual Account”</b></li>
                  <li>Input Nomor Virtual Account <b>{vacct}</b></li>
                  <li>Input Nominal misal. 10000</li>
                  <li>Klik <b>“Kirim”</b></li>
                  <li>Input Token</li>
                  <li>Klik <b>“Kirim”</b></li>
                  <li>Bukti bayar akan ditampilkan</li>
                  <li>Selesai</li>
                </ol>
              </div>
              <div data-header="Melalui Mobile Banking" className="accordion-item">
                <ol>
                  <li>Login Mobile Banking</li>
                  <li>Pilih <b>“Pembayaran Tagihan”</b></li>
                  <li>Pilih <b>“Virtual Account”</b></li>
                  <li>Input Nomor Virtual Account <b>{vacct}</b></li>
                  <li>Input nominal total pembayaran servis</li>
                  <li>Klik <b>“Kirim”</b></li>
                  <li>Input Token</li>
                  <li>Klik <b>“Kirim”</b></li>
                  <li>Bukti bayar akan ditampilkan</li>
                  <li>Selesai</li>
                </ol>
              </div>
            </Accordion>
            </div>
            );
       }
}

export default Permata;