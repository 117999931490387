import React, { Component } from 'react';
import Accordion from "../../Accordion";
import DanamonLogo from "../../image/DanamonLogo.png";

class Danamon extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
          <div>
             <img src={DanamonLogo} alt='Bank Danamon logo' className="logoBank"/>        
            <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
              onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}>
              <div data-header="Melalui Transfer ATM" className="accordion-item">
                <ol>
                  <li>Input PIN ATM Anda</li>
                  <li>Pilih Menu <b>“Pembayaran”</b></li>
                  <li>Pilih <b>“Virtual Account”</b></li>
                  <li>Masukan nomor Virtual Account <b>{vacct}</b></li>
                  <li>Masukkan nominal total pembayaran servis</li>
                  <li>Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar </li>
                  <li>Pilih <b>“Ya”</b> untuk memproses transaksi</li>
                  <li>Ambil bukti bayar anda</li>
                  <li>Selesai</li>
                </ol>
              </div>
              <div data-header="Melalui Internet Banking" className="accordion-item">
                <ol>
                  <li>Masuk ke menu <b>“Transfer ke Bank lain”</b></li> 
                  <li>Pilih <b>“Transfer Online”</b></li> 
                  <li>Pilih Bank tujuan, Bank Danamon</li> 
                  <li>Masukkan 16 digit nomor Virtual Account <b>{vacct}</b></li> 
                  <li>Masukkan nominal total pembayaran servis</li> 
                  <li>Pada layar konfirmasi pembayaran, harap pastikan nama tujuan dan nominal transaksi sudah tepat</li> 
                  <li>Konfirmasi pembayaran</li> 
                </ol>
              </div>
              <div
                data-header="Mobile Banking / D-Mobile"
                className="accordion-item"> 
                <ol>
                  <li>Login pada Aplikasi D-Mobile</li>
                  <li>Pilih menu <b>“Virtual Account”</b></li>
                  <li>Masukan 16 digit nomor virtual account <b>{vacct}</b></li>
                  <li>Masukan nominal total pembayaran servis</li>
                  <li>Pada layar konfirmasi pembayaran, pastikan transaksi sudah benar</li>
                  <li>pilih <b>“Ya”</b> untuk memproses transaksi</li>
                </ol>
              </div>
            </Accordion>
          </div>
          );
       }
}

export default Danamon