import React, { Component } from 'react';
import Accordion from "../../Accordion";
import maybankLogo from "../../image/maybankLogo.png";

class Maybank extends Component{
  constructor(props){
    super(props);
    this.state = {
      selectedIndex: 0
    };
 }
  render() {
    const vacct = this.props.vacct

    return (
      <div>
        <h5 className="caraPembayaran">Cara pembayaran melalui Bank:</h5>
        <img src={maybankLogo} alt='Maybank logo' className="logoBank"/> 
        <Accordion className="accordion" selectedIndex={this.state.selectedIndex}
            onChange={(index, expanded, selectedIndex) => console.log(`#${index} ${expanded ? 'expanded' : 'collapsed'} (selectedIndex: ${selectedIndex})`)}
          >
          <div
            data-header="Melalui Transfer ATM"
            className="accordion-item"
          >
            <ol>
              <li>Input kartu ATM dan PIN Anda</li>
              <li>Pilih Menu <b>“Pembayaran/Top Up Pulsa”</b></li>
              <li>Pilih <b>“Virtual Account”</b></li>
              <li>Input Nomor Virtual Account <b>{vacct}</b> </li>
              <li>Pilih <b>“Benar”</b></li>
              <li>Pilih <b>“Ya”</b></li>
              <li>Ambil bukti bayar Anda</li>
              <li>Selesai</li>
            </ol>
          </div>
          <div data-header="Melalui Internet Banking" className="accordion-item">
            <ol>
              <li>Login Internet Banking</li>
              <li>Pilih <b>“Rekening dan Transaksi”</b></li>
              <li>Pilih <b>“Maybank Virtual Account”</b></li>
              <li>Pilih <b>“Sumber Tabungan”</b></li>
              <li>Input Nomor Virtual Account <b>{vacct}</b></li>
              <li>Input biaya servis</li>
              <li>Klik <b>Submit</b></li>
              <li>Input SMS Token</li>
              <li>Bukti bayar ditampilkan</li>
              <li>Selesai</li>
            </ol>
          </div>
          <div data-header="SMS Banking" className="accordion-item">
            <ol>
              <li>SMS ke 69811</li>
              <li>Ketik TRANSFER (nomor virtual account) (nominal)</li>
              <li>Contoh: TRANSFER <b>{vacct}</b> 10000</li>
              <li>Kirim SMS</li>
              <li>Anda akan mendapat balasan Transfer dari rek ( nomor rekening anda ) ke rek ( Nomor Virtual Account ) sebesar Rp. 10.000 Ketik ( karakter acak )</li>
              <li>Balas SMS tersebut, ketik ( karakter acak )</li>
              <li>Kirim SMS</li>
              <li>Selesai</li>
            </ol>
          </div>
        </Accordion>
      </div>
    );
  }
}

export default Maybank;
