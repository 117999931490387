import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Landingpage from './Landingpage';
// import Dashboard from './Dashboard';
require('dotenv').config()
class App extends Component {
constructor(props) {
  super(props);
  this.state = {};
}

render() {
    return (
      <Router>
        {/*<Route path="/" component={Dashboard}/>*/}

        {/*<Route path="/validationVacct/:dt/:id" component={Landingpage}/>*/}
        <Route path="/validationVacct/:payment/:dt/:id" component={Landingpage}/>


        {/*<Route path="/validationVacct/:dt/:id" component={Landingpage} payment="care"/>
        <Route path="/validationVacctSufiz/:dt/:id" component={Landingpage} payment="sufiz"/>*/}
        {/*<img src={'assets/img/LOGO_ALQOLAM.png'} alt='logo alqolammm' style={{width: 70+'%'}}/>*/}
        {/*<img src={'assets/img/alqolamlogo.png'} alt='freepik pict' style={{width: 100+'%'}}/> */}
      </Router>
    );
  }
}

export default App;